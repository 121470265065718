/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { values } from 'lodash';
import React, { useMemo } from 'react';
import GoogleFontLoader from 'react-google-font';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CardSelectGrid } from 'components/Form/CardSelectGrid';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';
import { useGetDynamicFormFieldValues } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_PickLogoStyle.scss';
const { STYLE } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const _PickLogoStyle: React.FC<{ isMatch: boolean }> = ({ isMatch }) => {
    /***** HOOKS *****/

    const isMobile = useAppViewport(['xs', 'sm']);
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_fonts_meta_data, isLoading: isGetFontsMetaLoading } = katanaQuery.serviceID.meta.getFonts.useQuery(Number(id));
    const formValues = useGetDynamicFormFieldValues();

    /***** RENDER HELPERS *****/
    const filteredFonts = useMemo(
        () =>
            values(get_fonts_meta_data ?? {})
                .filter((font) => font.types.includes('brand'))
                .map((font) => ({
                    type: 'font',
                    fontID: font.id,
                    fontFamily: font.font_family,
                    text: formValues?.values?.name || font.font_family,
                    additionalContent: Boolean(formValues?.values?.name) && (
                        <Text className="PickLogoStyle__additionalContent" italic secondary size--s>
                            {font.name}
                        </Text>
                    )
                })),
        [get_fonts_meta_data, formValues]
    );
    const availableFonts = useMemo(() => {
        return values(get_fonts_meta_data ?? {})
            .filter((font) => font.types.includes('brand'))
            .map(({ name }) => ({ font: name }));
    }, [get_fonts_meta_data]);

    function onChangeProxy(value) {
        return { fonts: { brand: value } };
    }

    /***** RENDER *****/
    if (isGetFontsMetaLoading) {
        return <RequestLoader message="Loading Fonts..." />;
    }

    return (
        <>
            <GoogleFontLoader fonts={availableFonts} />
            {/* <NXBox.Title title="Pick Your Logo Style" /> */}
            {/* <br /> */}
            {/* <Text secondary size--s>
                Choose one of the logo styles below. This will show on your website header and footer.
            </Text> */}
            <Field
                name={STYLE}
                component={CardSelectGrid.ReduxForm}
                alignItems="stretch"
                textAlign="left"
                selectionText="Choose logo style"
                options={filteredFonts}
                itemsPerRow={isMobile ? 1 : 2}
                validate={isMatch ? [requiredFieldValidation] : []}
                onChangeProxy={onChangeProxy}
                valueProxy={(value) => value?.fonts?.brand}
            />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _PickLogoStyle };
