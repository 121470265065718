/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetMultiLoaderData() {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { isLoading: isGetNavigationLoading } = katanaQuery.serviceID.getNavigation.useQuery(id);
    const { isLoading: isGetKatanaSiteSectionsLoading } = katanaQuery.serviceID.getSections.useQuery(id);
    const { isLoading: isGetKatanaSiteSectionDefinitionsLoading } = katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);

    /***** RENDER *****/
    return RequestLoader.MultiLoader.useLoadersData([
        { condition: isGetNavigationLoading, message: 'Loading Customer Engagement Info...' },
        { condition: isGetKatanaSiteSectionDefinitionsLoading, message: 'Loading section definitions...' },
        { condition: isGetKatanaSiteSectionsLoading, message: 'Loading existing sections...' }
    ]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
