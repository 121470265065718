/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**
 * @param {number} id
 * @param {string} path
 */
export function getServiceInfoDataFromPath(id, path) {
    const serviceInfo = katanaQuery.serviceID.getService.getQueryData(id);
    if (!serviceInfo) {
        return null;
    }
    if (!('data' in serviceInfo)) {
        return;
    }
    return _.get(serviceInfo.data, path);
}
