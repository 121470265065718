/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import _, { cloneDeep, has, merge } from 'lodash';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkDomain } from 'containers/katana/queries/checkDomain';
import { applyNewSectionSort } from 'containers/katana/queries/methods/applyNewSectionSort';
import { sortSectionIntoCorrectOrder } from 'containers/katana/queries/methods/sortSectionOrder';
import { getSingleSection } from 'containers/katana/queries/serviceID/getSection';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { createBaseQueryKey } from 'utilities/methods/tanstack';
import { createOptimisticUpdateMethod } from 'utilities/methods/tanstack/createOptimisticUpdateMethod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import type { SetupStages } from 'containers/katana/consts';
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';
import type { NXQuery } from 'utilities/methods/tanstack/types';
import type { NXUtils } from 'utilities/types';

type TData = Awaited<ReturnType<typeof KATANA_API.katana.service_id.GET>>;

function createQueryKey(serviceID: number) {
    return createBaseQueryKey(['katana', 'service', serviceID] as const);
}

function createQueryOptions(serviceID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: ({ signal }) =>
            KATANA_API.katana.service_id.GET(serviceID, signal).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(serviceID: number) {
    return queryClient.prefetchQuery(createQueryOptions(serviceID));
}

function cancelQueries(serviceID: number) {
    return queryClient.cancelQueries({ queryKey: createQueryKey(serviceID) });
}

function getQueryData(serviceID: number): undefined | TData {
    return queryClient.getQueryData(createQueryKey(serviceID));
}

function invalidateQueries(serviceID: number) {
    const queryKey = createQueryKey(serviceID);
    // Whenever the service is invalidated, we also need to invalidate the preview check as it uses the service data
    queryClient.invalidateQueries({ queryKey: [...queryKey, 'preview'] });
    const queryData = getQueryData(serviceID);
    // Whenever the service is invalidated, we also need to invalidate the domain check as it uses the service data
    if (queryData?.status !== 200 && queryData?.data?.attributes?.name) {
        checkDomain.invalidateQueries(queryData.data.attributes.name);
    }
    return queryClient.invalidateQueries({
        queryKey
    });
}

function setQueryData(serviceID: number, updater: (oldData: TData) => TData | undefined) {
    queryClient.setQueryData(createQueryKey(serviceID), updater);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the sections on the site
 */
function _useQuery(serviceID: number, options?: NXQuery.TUseQueryOptions<typeof createQueryOptions>) {
    return useQuery({ ...createQueryOptions(serviceID), ...options });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
function optimisticSectionSort(serviceID: number, sectionIDs: number[]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        clonedData.data.sections = applyNewSectionSort(sectionIDs, clonedData.data.sections);

        return clonedData;
    });
}

function optimisticallyAddLaunchChecklistUpdate(serviceID: number, launchChecklistKey: SetupStages) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);
        if (!has(clonedData, 'data.attributes.launch_checklist')) {
            return;
        }

        clonedData.data.attributes.launch_checklist = _.uniq([...clonedData.data.attributes.launch_checklist, launchChecklistKey]);

        return clonedData;
    });
}

function optimisticNameUpdate(serviceID: number, sectionID: number, name: string) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        const section = clonedData.data.sections.find((section) => section.id === sectionID);

        if (!section) {
            return;
        }

        getSingleSection.optimisticUpdate({ sectionID, serviceID }, 'data.name', name);

        section.name = name;
        return clonedData;
    });
}

function optimisticSectionAddition(serviceID: number, section: Artah.Katana.ID.GET._200['data']['sections'][number]) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        clonedData.data.sections.push(section);

        sortSectionIntoCorrectOrder(clonedData.data.sections);

        return clonedData;
    });
}

function optimisticSectionPropertiesUpdate(
    serviceID: number,
    sectionID: number,
    propertiesData: Artah.Katana.ID.GET._200['data']['sections'][number]['properties']
) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.sections')) {
            return;
        }

        const section = clonedData.data.sections.find((section) => section.id === sectionID);

        if (!section) {
            return;
        }

        getSingleSection.optimisticUpdate({ sectionID, serviceID }, 'data.properties', propertiesData);

        section.properties = propertiesData;

        return clonedData;
    });
}

function optimisticSiteColourUpdate(serviceID: number, colour: string) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);

        if (!has(clonedData, 'data.site.color')) {
            return;
        }

        clonedData.data.site.color = colour;

        return clonedData;
    });
}

function applyOptimisticStyleUpdate(serviceID: number, style: NXUtils.RecursivePartial<KatanaAPI.Katana.ServiceID.GET.Style>) {
    setQueryData(serviceID, (oldData) => {
        if (oldData?.status !== 200) {
            return;
        }

        const clonedData = cloneDeep(oldData);
        if (!has(clonedData, 'data.site.style')) {
            return;
        }

        clonedData.data.site.style = merge({}, clonedData.data.site.style, style);

        return clonedData;
    });
}

export const getService = Object.freeze({
    useQuery: _useQuery,
    optimisticSectionSort,
    optimisticNameUpdate,
    optimisticSectionAddition,
    optimisticSectionPropertiesUpdate,
    optimisticSiteColourUpdate,
    optimisticallyAddLaunchChecklistUpdate,
    applyOptimisticStyleUpdate,
    optimisticUpdate: createOptimisticUpdateMethod<number, TData>(setQueryData),
    cancelQueries,
    invalidateQueries,
    prefetchQuery,
    getQueryData,
    createQueryKey,
    createQueryOptions
});
