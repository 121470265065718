/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useIsMutating } from '@tanstack/react-query';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { AcknowledgementBox } from 'components/AcknowledgementBox';
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { CancelServiceContent } from 'components/CancelServiceContent';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useResetKatanaService } from 'containers/katana/queries/mutations';
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ScrollableComponent } from 'components/ScrollableComponent';
import { katanaServicePages } from 'containers/katana/consts';
import './_ResetKatanaServiceModule.scss';

const { SETUP } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const ResetKatanaServiceModule = function ResetKatanaServiceModule() {
    /***** STATE *****/
    const { value: isConfirmResetShowing, setTrue: showConfirmReset, setFalse: closeConfirmReset } = useBoolean(false);
    const [acknowledged, setAcknowledged] = useState(false);

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    const { getKatanaDestination } = useKatanaURL();
    const history = useHistory();

    /***** QUERIES *****/
    const { data: katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);
    const { mutate: mutateResetKatanaService } = useResetKatanaService(id);
    const resetKatanaServiceMutations = useIsMutating({ mutationKey: useResetKatanaService.mutationKey });

    /***** FUNCTIONS *****/
    function handleConfirmSiteReset() {
        mutateResetKatanaService(null, {
            onSuccess: () => {
                history.push(getKatanaDestination(SETUP.BASIC_DETAILS));
            }
        });
    }
    /***** RENDER *****/
    return (
        <ScrollableComponent ready>
            <NXBox className="ResetKatanaServiceModule">
                <NXBox.Top
                    title="Reset VIPsites Website"
                    description="Resetting this VIPsites service will delete all content, files, and configurations associated with this website. You will be directed to the setup process again. Choose this option if you want to start over and recreate your website from scratch."
                >
                    <OutlineButton type="onClick" onClick={showConfirmReset}>
                        Reset Site
                    </OutlineButton>
                </NXBox.Top>
                <Modal
                    className="ResetKatanaServiceModule__modal"
                    isOpen={isConfirmResetShowing}
                    onClose={closeConfirmReset}
                    ariaLabel="Reset VIPsites Website"
                >
                    {resetKatanaServiceMutations > 0 ? (
                        <RequestLoader message="Resetting your VIPsites site..." heightAuto fillHeight={66} />
                    ) : (
                        <CancelServiceContent
                            title="Reset VIPsites Website"
                            primaryName={katana_service_data?.attributes?.name}
                            primaryProduct="VIPsites"
                        >
                            <Padding xy={4}>
                                <Text size--m secondary align--center>
                                    You are about to reset your VIPsites website.
                                    <br />
                                    This action will remove all data related to this VIPsites website, including any business details, images,
                                    content, and theme configurations. After the reset is complete, you will be taken through the setup process again.
                                </Text>
                            </Padding>
                            <AcknowledgementBox
                                title={
                                    <Text bold capitalize>
                                        Confirmation
                                    </Text>
                                }
                                titleNoPadding
                            >
                                <CheckBoxList.Item isChecked={acknowledged} onChange={setAcknowledged}>
                                    <Text secondary span size--xss>
                                        I understand that upon resetting my website that all my progress and details related to this website will be
                                        removed, including any business details, images, content and theme configurations.
                                    </Text>
                                </CheckBoxList.Item>
                            </AcknowledgementBox>
                            <SolidButton disabled={!acknowledged} type="onClick" onClick={handleConfirmSiteReset}>
                                Reset
                            </SolidButton>
                        </CancelServiceContent>
                    )}
                </Modal>
            </NXBox>
        </ScrollableComponent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ResetKatanaServiceModule };
