/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import NXBox from 'components/NXBox';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useDomainOptionsCustomField from 'containers/katana/containers/ContentEditorLightbox/formHandlers/registerDomainFormHandler/useDomainOptionsCustomField';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const EligibilityFormSection = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { watch } = useFormContext();

    // .AU
    const eligibilityTypesData = useDomainOptionsCustomField('Eligibility Type');
    const eligibilityNumberData = useDomainOptionsCustomField('Eligibility Number');
    const eligibilityTypesValue = watch('eligibility_type');

    /***** QUERIES *****/
    const { data: domain_options_data } = katanaQuery.serviceID.getDomainOptions.useQuery(id);

    /***** RENDER HELPERS *****/
    const isABNorACNRequired = useMemo(
        () => Boolean(domain_options_data?.attributes?.custom_fields) && eligibilityTypesData && eligibilityNumberData,
        [domain_options_data]
    );

    const renderEligibilityTypeToolTip = () => {
        if (eligibilityTypesValue === 'Citizen/Resident') {
            return (
                <Tooltip
                    info={`To process this registration, you will be required to perform either a Driver's Licence, Passport or Medicare Card ID verification.
                    Once you have paid for your order, we will contact you via email to complete the required ID verification.`}
                />
            );
        }

        if (eligibilityTypesData?.description) {
            return <Tooltip info={eligibilityTypesData?.description} />;
        }

        return;
    };

    const eligibilityOptions = useMemo(() => eligibilityTypesData?.options?.map((value) => ({ label: value, value })), [eligibilityTypesData]);

    /***** RENDER *****/
    if (!isABNorACNRequired) return;
    return (
        <>
            <NXBox.Top title="Domain Eligibility details" />
            <NXBox.DefaultPadding>
                <Flex direction="column">
                    <Select.HookForm
                        label={<>Eligibility Type {renderEligibilityTypeToolTip()}</>}
                        name="eligibility_type"
                        options={eligibilityOptions}
                    />
                    {eligibilityTypesValue === 'Citizen/Resident' ? (
                        ''
                    ) : (
                        <Input.HookForm
                            label={
                                <>
                                    Eligibility Number
                                    {Boolean(eligibilityNumberData?.description) && <Tooltip info={eligibilityNumberData?.description} />}
                                </>
                            }
                            name="eligibility_number"
                        />
                    )}
                </Flex>
            </NXBox.DefaultPadding>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default EligibilityFormSection;
