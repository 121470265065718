/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorRenderModules from '../contentEditorRenderModules';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import useModifiedInitialValues from 'containers/katana/hooks/useModifiedInitialValues';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {FormHandlerComponent}
 */
const FinalDetailsFormHandler = ({ subpageRouteData }) => {
    /***** STATE *****/
    const [isSubmitting, setIsSubmitting] = useState(false);
    /***** HOOKS *****/
    const { id, subpage } = useSetupEditorRouteParams();
    const { modules } = subpageRouteData;
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { data: get_katana_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(id);

    const { mutateAsync: mutateUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(id, null, { context: 'favicon' });

    /***** HOOKS *****/
    const queryData = useMemo(
        () => ({
            ...(get_katana_site_info_data ?? {})
        }),
        [get_katana_site_info_data]
    );
    const newInitialValues = useModifiedInitialValues(queryData, modules, isSubmitting);

    /***** FUNCTIONS *****/
    /**
     * @param {Record<string,unknown>} values
     */
    function handleSubmit(values) {
        setIsSubmitting(true);
        return Promise.all([mutateUpdateKatanaSiteInfo(values)])
            .then(() => {
                katanaQuery.serviceID.getService.optimisticallyAddLaunchChecklistUpdate(id, 'finishing-touches-completed');
                performRouting();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    /***** RENDER *****/
    if (isGetKatanaSiteInfoLoading) {
        return <RequestLoader message="Loading Details..." />;
    }
    return (
        <ContentEditorRenderModules subpageRouteData={subpageRouteData} subpage={subpage} onSubmit={handleSubmit} initialValues={newInitialValues} />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default FinalDetailsFormHandler;
