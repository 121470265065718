/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useState } from 'react';
import type { DropzoneRef, FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { NXDropZoneFileRenderer } from 'components/Form/NXDropZone/NXDropZoneFileRenderer';
import { _NXDropZoneError as NXDropZoneError } from 'components/Form/NXDropZone/_NXDropZone/_NXDropZoneError';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { formatBytes } from 'utilities/methods/commonActions/formatBytes';
import { oxfordCommaStyle } from 'utilities/methods/commonActions/oxfordCommaStyle';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { MAX_FILE_SIZE, defaultAccepts } from 'components/Form/NXDropZone/consts';
import './_NXDropZone.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _NXDropZone: React.FC<NXDropZoneNamespace.BaseProps> = ({
    message,
    value = [],
    onChange,
    className,
    zoneRef,
    accept = defaultAccepts,
    singleFile = false,
    fileLimit = 3,
    compact = false,
    renderer: FileRenderer = NXDropZoneFileRenderer,
    preventFileDisplay,
    onDropZoneDrop
}) => {
    /***** STATE *****/
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
    // This variable is set to true only when the number of uploaded files equals the file limit, AND another file has been attempted to be uploaded.
    const [outsideFileLimit, setOutsideFileLimit] = useState(false);

    const isFilesArray = Array.isArray(value);
    const files = isFilesArray ? value : [];

    /***** FUNCTIONS *****/
    function onHandleDrop(newFiles: File[]) {
        onDropZoneDrop?.(newFiles);
        /**
         * If it's a single file, just change it to that file
         */
        setRejectedFiles([]);
        if (singleFile) {
            setOutsideFileLimit(false);
            onChange(newFiles?.[0]);
            return;
        }

        /**
         * File Array instead of single file
         */
        const allFiles = [...files, ...newFiles];
        if (allFiles.length <= fileLimit) {
            onChange(allFiles);
        }

        setOutsideFileLimit(allFiles.length > fileLimit);
    }

    function removeFile(i = -1) {
        setOutsideFileLimit(false);

        /**
         * If it's a single file, just change it to that file
         */
        if (singleFile) {
            onChange(null);
            return;
        }

        /**
         * File Array instead of single file
         */
        const newFiles = [...files].filter((_, index) => index !== i);
        onChange(newFiles);
    }

    /***** RENDER HELPERS *****/
    const acceptedExtensions = Object.values(accept).flat(2);
    const isUnderFileLimit = singleFile || files.length < fileLimit;

    function renderMaxUploadError() {
        if (outsideFileLimit) {
            return (
                <Text warn semiBold size--s align--center>
                    You can only upload a maximum of {fileLimit} file{fileLimit === 1 ? '' : 's'}.
                </Text>
            );
        }

        if (!isUnderFileLimit) {
            return (
                <Text notice semiBold size--s align--center>
                    Max number of files reached ({fileLimit}).
                </Text>
            );
        }
    }

    function renderInteractionInformation() {
        if (compact) {
            if (message) {
                return message;
            }

            return (
                <Text size--s semiBold align--center secondary={!isUnderFileLimit}>
                    Drop or{' '}
                    <Text info semiBold span>
                        choose a photo
                    </Text>
                </Text>
            );
        }

        return (
            <>
                {message ? (
                    message
                ) : (
                    <Text size--m semiBold align--center secondary={!isUnderFileLimit}>
                        Drag and drop or{' '}
                        <Text info size--m semiBold span>
                            choose a photo
                        </Text>{' '}
                        to upload
                    </Text>
                )}
                <Text secondary={isUnderFileLimit} inactive={!isUnderFileLimit} size--s align--center>
                    Please upload a {oxfordCommaStyle(acceptedExtensions, 'or')}
                    <br />
                    Maximum file upload size is {formatBytes(MAX_FILE_SIZE)}
                </Text>
            </>
        );
    }

    function setZoneRef(ref: DropzoneRef) {
        if (!zoneRef) {
            return;
        }

        if (typeof zoneRef === 'function') {
            zoneRef(ref);
            return;
        }

        zoneRef.current = ref;
    }

    const gridProps = {
        columns: 'repeat(6, 1fr)',
        className: 'NXDropZone__fileRendererGrid'
    };

    /***** RENDER *****/
    return (
        <div className={classNames('NXDropZone', className, { 'NXDropZone--disabled': !isUnderFileLimit })}>
            <Dropzone
                ref={setZoneRef}
                accept={accept}
                maxSize={MAX_FILE_SIZE}
                disabled={!isUnderFileLimit}
                onDrop={onHandleDrop}
                onDropRejected={setRejectedFiles}
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps({ className: 'dropzone' })}
                        className={classNames('NXDropZone__presentation', {
                            'NXDropZone__presentation--compact': compact
                        })}
                    >
                        <PhosphorIcons.UploadSimple secondary={isUnderFileLimit} inactive={!isUnderFileLimit} size={30} />
                        <input {...getInputProps()} />

                        {renderInteractionInformation()}

                        {renderMaxUploadError()}
                    </div>
                )}
            </Dropzone>

            {preventFileDisplay ? null : (
                <>
                    {singleFile && !isFilesArray && !Array.isArray(files) ? (
                        <WrapWithComponent wrap={FileRenderer === NXDropZoneFileRenderer} component={Grid} {...gridProps}>
                            <FileRenderer file={files} removeFile={removeFile} />
                        </WrapWithComponent>
                    ) : (
                        !!files.length && (
                            <WrapWithComponent wrap={FileRenderer === NXDropZoneFileRenderer} component={Grid} {...gridProps}>
                                {FileRenderer === NXDropZoneFileRenderer ? (
                                    files.map((file, i) => <FileRenderer key={i} file={file} index={i} removeFile={removeFile} />)
                                ) : (
                                    <FileRenderer file={files} removeFile={removeFile} />
                                )}
                            </WrapWithComponent>
                        )
                    )}
                </>
            )}
            <NXDropZoneError maxFileSize={MAX_FILE_SIZE} rejectedFiles={rejectedFiles} />
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { _NXDropZone };
