/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SiteSetupStage, katanaPages, katanaServicePages, katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface UseKatanaChecklistDataProps {
    iconSize?: number;
    serviceID?: number;
}

const { BASIC_DETAILS, PRESET_CONTENT, COLOUR_FONTS } = katanaSubPageEnums;

const { SETUP, EDITOR } = katanaServicePages;
const { ADD_SECTION } = katanaPages;
const { FinishingTouchesCompleted } = SiteSetupStage;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useKatanaChecklistData(props: UseKatanaChecklistDataProps = {}) {
    const { iconSize = 26, serviceID } = props;
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const ID = _.has(props, 'serviceID') ? serviceID : id;

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(ID);
    const launch_checklist = get_katana_service_data?.attributes?.launch_checklist ?? [];

    /***** HOOK RESULTS *****/
    return [
        {
            icon: <PhosphorIcons.ClipboardText black size={iconSize} />,
            title: 'Basic Information',
            to: launch_checklist.includes(katanaContentEditorRoutes[BASIC_DETAILS].checkListKey) ? EDITOR.BASIC_DETAILS : SETUP.BASIC_DETAILS,
            checkListKey: katanaContentEditorRoutes[BASIC_DETAILS].checkListKey,
            prefetch: katanaContentEditorRoutes[BASIC_DETAILS].prefetch
        },
        {
            icon: <PhosphorIcons.Layout black size={iconSize} />,
            title: 'Template and Content',
            to: launch_checklist.includes(katanaContentEditorRoutes[PRESET_CONTENT].checkListKey) ? ADD_SECTION : SETUP.PRESET_CONTENT,
            checkListKey: katanaContentEditorRoutes[PRESET_CONTENT].checkListKey,
            prefetch: katanaContentEditorRoutes[PRESET_CONTENT].prefetch
        },
        // {
        //     icon: <PhosphorIcons.NavigationArrow black />,
        //     title: 'Navigation and Socials',
        //     to: launch_checklist.includes(katanaContentEditorRoutes[NAVIGATION].checkListKey) ? EDITOR.NAVIGATION : SETUP.NAVIGATION,
        //     checkListKey: katanaContentEditorRoutes[NAVIGATION].checkListKey,
        //     prefetch: katanaContentEditorRoutes[NAVIGATION].prefetch
        // },
        {
            icon: <PhosphorIcons.Palette black size={iconSize} />,
            title: 'Colours and Fonts',
            to: launch_checklist.includes(katanaContentEditorRoutes[COLOUR_FONTS].checkListKey) ? EDITOR.COLOUR_FONTS : SETUP.COLOUR_FONTS,
            checkListKey: katanaContentEditorRoutes[COLOUR_FONTS].checkListKey,
            prefetch: katanaContentEditorRoutes[COLOUR_FONTS].prefetch
        },
        {
            icon: <PhosphorIcons.RocketLaunch black size={iconSize} />,
            title: 'Finishing Touches',
            to: launch_checklist.includes(FinishingTouchesCompleted) ? EDITOR.FINALISE : SETUP.FINALISE,
            checkListKey: FinishingTouchesCompleted
        }
    ];
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
