/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { isArray } from 'lodash';
import { useState } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaMutation } from 'containers/katana/queries/mutationTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { iterativelyUploadFiles } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/methods';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';
import type { UseKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/types';
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Options = {
    context?: KatanaAPI.Katana.ServiceID.Uploads.POST.Parameters['formData']['context'];
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useKatanaFileUpload(serviceID: number, onChange: UseKatanaFileUpload.OnChange, options: Options = {}) {
    /***** STATE *****/
    const [isUploading, setIsUploading] = useState(false);

    /***** QUERIES *****/
    const { mutateAsync: uploadFile } = katanaMutation.uploadFile.useMutation(serviceID);

    /***** FUNCTIONS *****/
    function onUploadStart() {
        setIsUploading(true);
    }

    function onUploadEnd() {
        setIsUploading(false);
    }

    const onChangeProxy = async (files: NXDropZoneNamespace.Values) => {
        const isFilesArray = isArray(files);
        const filteredFiles = (isFilesArray ? files.flat() : [files]).filter((file) => file instanceof File);
        onUploadStart?.();
        for (const filteredFile of filteredFiles) {
            try {
                const uploadResults = await iterativelyUploadFiles([filteredFile], uploadFile, options);
                if (isFilesArray) {
                    onChange(uploadResults);
                } else {
                    onChange(uploadResults[0]);
                }
            } catch (error) {
                console.error('Error uploading files', error);
            }
        }
        onUploadEnd?.();
    };

    /***** HOOK RESULTS *****/
    return {
        isUploading,
        onChangeProxy
    };
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export { useKatanaFileUpload };
