/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { serviceNav } from 'config/config';
import { useEffect } from 'react';
import { Route, useLocation } from 'react-router';
import { Redirect, Switch, useHistory } from 'react-router-dom';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import RequestLoader from 'components/Loaders/Request';
import Subnav from 'components/Subnav';
import Title from 'components/Title';
import { Flex } from 'components/Utils/Flex';
import { RefProvider } from 'components/Utils/RefProvider';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { SitePreviewBrowserBox } from 'containers/katana/components/sitePreviewBrowserBox/sitePreviewBrowserBox';
import { CancelKatanaServiceModule } from 'containers/katana/modules/cancel';
import KatanaDNSModule from 'containers/katana/modules/dns';
import { KatanaFinaliseModule } from 'containers/katana/modules/finalise';
import { KatanaLaunchChecklist } from 'containers/katana/modules/launchChecklist';
import { KatanaPresetContentModule } from 'containers/katana/modules/presetContent';
import { ResetKatanaServiceModule } from 'containers/katana/modules/reset';
import { KatanaSetup } from 'containers/katana/modules/setup';
import { KatanaPageBaseRouter } from './components/pageBaseRouter';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useRegisterTestRoutes } from 'components/TestRoutes/hooks/useRegisterTestRoutes';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import useKatanaURL from './hooks/useKatanaURL';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import useDomainIsNotRegistered from 'containers/katana/containers/ContentEditorLightbox/useDomainIsNotRegistered';
import { InformationAndAppearanceModule } from 'containers/katana/modules/informationAndAppearance';
import { WebsiteAppearanceCondensedModule } from 'containers/katana/modules/informationAndAppearance/WebsiteAppearanceCondensedModule';
import { WebsiteInformationCondensedModule } from 'containers/katana/modules/informationAndAppearance/WebsiteInformationCondensedModule';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { KatanaTestRoutes, katanaServicePages } from './consts';

const { OVERVIEW, ADMIN, EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaIndex = () => {
    /***** HOOKS *****/
    const location = useLocation();
    const { id } = useSetupEditorRouteParams();
    const history = useHistory();
    const { getKatanaDestination } = useKatanaURL();
    const isMobile = useAppViewport(['sm', 'xs']);

    /***** QUERIES *****/
    const { data: get_katana_service_data, isError } = katanaQuery.serviceID.getService.useQuery(id);

    /***** EFFECTS *****/
    useEffect(() => {
        if (isError) {
            history.push(serviceNav.VIPsites.link);
        }
    }, [isError]);

    /***** RENDER HELPERS *****/
    const { launch_checklist, is_setup_complete, name } = get_katana_service_data?.attributes ?? {};
    const showContentModules = get_katana_service_data?.sections?.length || launch_checklist?.length || is_setup_complete;

    const modules = {
        [OVERVIEW.to]: [
            {
                sidebarLink: {
                    label: 'Setup Website',
                    link: getKatanaDestination(OVERVIEW, 'setup')
                },
                component: <KatanaSetup />,
                condition: !showContentModules,
                identifier: `${serviceNav.VIPsites.key}/setup`
            },
            {
                sidebarLink: {
                    label: 'Launch Checklist',
                    link: getKatanaDestination(OVERVIEW, 'launch-checklist')
                },
                component: <KatanaLaunchChecklist />,
                condition: !is_setup_complete,
                identifier: `${serviceNav.VIPsites.key}/launch-checklist`
            },
            {
                sidebarLink: {
                    label: 'Website Information/Appearance',
                    link: getKatanaDestination(OVERVIEW.BASIC_DETAILS)
                },
                component: <InformationAndAppearanceModule />,
                condition: showContentModules && !isMobile,
                identifier: `${serviceNav.VIPsites.key}/website-information-appearance`
            },
            {
                sidebarLink: {
                    label: 'Basic Information',
                    link: getKatanaDestination(OVERVIEW.BASIC_DETAILS)
                },
                component: <WebsiteInformationCondensedModule />,
                condition: showContentModules && isMobile,
                identifier: `${serviceNav.VIPsites.key}/basic-information`
            },
            {
                sidebarLink: {
                    label: 'Colours and Fonts',
                    link: getKatanaDestination(OVERVIEW.COLOUR_FONTS)
                },
                component: <WebsiteAppearanceCondensedModule />,
                condition: showContentModules && isMobile,
                identifier: `${serviceNav.VIPsites.key}/colours-and-fonts`
            },
            {
                sidebarLink: {
                    label: 'Template and Content',
                    link: getKatanaDestination(OVERVIEW.PRESET_CONTENT)
                },
                component: <KatanaPresetContentModule />,
                condition: showContentModules,
                identifier: `${serviceNav.VIPsites.key}/template-content`
            },
            {
                sidebarLink: {
                    label: 'Finishing Touches',
                    link: getKatanaDestination(OVERVIEW.FINALISE)
                },
                component: <KatanaFinaliseModule />,
                condition: showContentModules,
                identifier: `${serviceNav.VIPsites.key}/finishing-touches`
            }
        ],
        [ADMIN.to]: [
            {
                sidebarLink: {
                    label: 'VIPsites DNS',
                    link: getKatanaDestination(ADMIN.DNS)
                },
                component: <KatanaDNSModule />,
                condition: true,
                identifier: `${serviceNav.VIPsites.key}/dns`
            },
            {
                sidebarLink: {
                    label: 'Reset VIPsites Service',
                    link: getKatanaDestination(ADMIN.RESET)
                },
                component: <ResetKatanaServiceModule />,
                condition: true,
                identifier: `${serviceNav.VIPsites.key}/reset-service`
            },
            {
                sidebarLink: {
                    label: 'Cancel VIPsites Service',
                    link: getKatanaDestination(ADMIN.CANCEL)
                },
                component: <CancelKatanaServiceModule />,
                condition: true,
                identifier: `${serviceNav.VIPsites.key}/cancel-service`
            }
        ]
    };

    const siteStatus = /** @type {import("containers/katana/types").Katana.SiteStatus} */ (get_katana_service_data?.attributes?.site_status);
    const katanaSiteURL = `https://${name}`;
    const canNavigateToSite = siteStatus === 'published';

    const renderTitle = () => {
        return (
            <Title
                serviceTitle={name ? <Flex>{name}</Flex> : <RequestLoader height={23} fillHeight={23} width={60} />}
                action={
                    canNavigateToSite && (
                        <OutlineButton
                            className="TitleShared__button"
                            width="auto"
                            color="primary"
                            type="anchor"
                            href={katanaSiteURL}
                            target="_blank"
                        >
                            <Text semiBold lead--1>
                                View Site
                            </Text>
                        </OutlineButton>
                    )
                }
                serviceType="vip-sites"
                serviceSubTitle="VIPsites WEBSITE BUILDER"
            />
        );
    };

    const testRoutes = useRegisterTestRoutes(KatanaTestRoutes);

    /***** QUERIES *****/
    const { isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getService.useQuery(Number(id));
    const isDomainNotRegistered = useDomainIsNotRegistered();
    /***** RENDER *****/
    if (isGetKatanaSiteInfoLoading) {
        return <RequestLoader message="VIP's gathering..." />;
    }
    return (
        <RefProvider>
            <Subnav pathname={location.pathname} />
            {renderTitle()}
            <Switch>
                <Route
                    exact
                    path={`${serviceNav.VIPsites.link}/:id`}
                    render={() => {
                        console.log('!showContentModules', !showContentModules);

                        return <Redirect to={getKatanaDestination(OVERVIEW)} />;
                    }}
                />
                <Route
                    exact
                    path={`${serviceNav.VIPsites.link}/:id/overview`}
                    render={() => {
                        if (!showContentModules) {
                            return <Redirect to={getKatanaDestination(OVERVIEW, 'setup')} />;
                        }
                        return <Redirect to={getKatanaDestination(OVERVIEW.BASIC_DETAILS)} />;
                    }}
                />
                {isDomainNotRegistered && (
                    <Route
                        exact
                        path={`${serviceNav.VIPsites.link}/:id/:page/launch`}
                        render={() => {
                            return <Redirect to={getKatanaDestination(EDITOR.REGISTER_DOMAIN)} />;
                        }}
                    />
                )}
                {testRoutes}

                <Route exact path={`${serviceNav.VIPsites.link}/:id/:page/:subpage?/:childpage?`}>
                    <KatanaPageBaseRouter modules={modules} />
                </Route>
            </Switch>
            <SitePreviewBrowserBox />
        </RefProvider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default KatanaIndex;
