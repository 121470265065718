/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useMemo, useRef, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ChooseLayoutCard from 'containers/katana/components/chooseLayoutCard';
import { SitePresetPreviewBrowserBox } from 'containers/katana/components/sitePreviewBrowserBox/sitePresetPreviewBrowserBox';
import { ValidFormSubmitButtonControlled } from 'containers/katana/containers/contentEditorModules/validFormSubmitButton/validFormSubmitButtonControlled';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useStore } from 'utilities/hooks/useStore';
import { createKey } from 'utilities/methods/createKey';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { createPictureInPictureState } from 'components/PictureInPicture/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { WrappedFieldInputProps } from 'redux-form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ThemePresetGridProps = {
    input: WrappedFieldInputProps;
    showLayoutRefinement: () => void;
};

interface ThemePresetGridOptions {
    id: string;
    name: string;
    description: string;
    preview: string;
    color: string;
    style: any;
    sections: any;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ThemePresetGridReduxForm: React.FC<ThemePresetGridProps> = ({ input, showLayoutRefinement }) => {
    const { value, onChange } = input;

    /***** STATE *****/
    const [presetID, setPresetID] = useState(null);

    /***** HOOKS *****/
    const { current: stateStoreData } = useRef(createPictureInPictureState('hidden'));
    const [, setPictureState] = useStore(stateStoreData.store);
    const { id } = useSetupEditorRouteParams();
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { data: get_katana_theme_presets_data, isLoading: isGetKatanaThemePresetsLoading } =
        katanaQuery.serviceID.meta.getThemePresets.useQuery(id);

    /***** RENDER HELPERS *****/
    const themePresets: ThemePresetGridOptions[] = useMemo(() => Object.values(get_katana_theme_presets_data ?? {}), [get_katana_theme_presets_data]);

    /***** RENDER *****/
    if (isGetKatanaThemePresetsLoading) {
        return <RequestLoader message="Loading Presets..." />;
    }

    return (
        <>
            <Grid columns={isMobile ? '100%' : '1fr 1fr'} gap={4}>
                {themePresets.map((preset, i) => {
                    const { id: _id, name, preview } = preset;
                    const key = createKey(preset, i);
                    return (
                        <ChooseLayoutCard
                            imageURL={preview}
                            key={key}
                            layoutID={_id}
                            layoutName={name}
                            onClick={() => {
                                setPresetID(_id);
                                onChange(_id);
                                if (_id === 'katana.v1.custom') {
                                    setPictureState('hidden');
                                } else {
                                    setPictureState('visible');
                                }
                            }}
                            onPreviewClick={() => {
                                setPictureState('maximised');
                                setPresetID(_id);
                            }}
                            selected={value === _id}
                        />
                    );
                })}
            </Grid>

            <ValidFormSubmitButtonControlled disabled={!value} onClick={showLayoutRefinement}>
                Confirm and continue
            </ValidFormSubmitButtonControlled>

            <SitePresetPreviewBrowserBox presetID={presetID} selectedPresetID={value} stateStoreData={stateStoreData} />
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
