/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import queryClient from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';
import { createBaseQueryKey } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaAPI } from 'utilities/api/katana/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

function createQueryKey(params: KatanaAPI.Katana.Site.ServiceID.Meta.Palette.GET.Params) {
    return createBaseQueryKey(['katana', 'service', params.serviceID, 'meta', 'palette', params.colour] as const);
}

function createQueryOptions(params: KatanaAPI.Katana.Site.ServiceID.Meta.Palette.GET.Params) {
    return queryOptions({
        queryKey: createQueryKey(params),
        queryFn: () =>
            KATANA_API.katana.site.service_id.meta.palette.GET(params).catch((e) => {
                handleDefaultErrorNotification(e);
                throw e;
            }),
        enabled: TruthKeys(params),
        staleTime: Infinity,
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        }
    });
}

function prefetchQuery(params: KatanaAPI.Katana.Site.ServiceID.Meta.Palette.GET.Params) {
    return queryClient.prefetchQuery(createQueryOptions(params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Gets the colours meta data for the site
 */
function _useQuery(params: KatanaAPI.Katana.Site.ServiceID.Meta.Palette.GET.Params) {
    return useQuery(createQueryOptions(params));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const palette = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    createQueryKey,
    createQueryOptions
});
