/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { Field } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { ColourSelect } from 'components/Form/ColourSelect';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { requiredFieldValidation } from 'utilities/methods/form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { webpageColourThemeFormFieldsEnum } from './consts';

const { COLOUR } = webpageColourThemeFormFieldsEnum;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _WebpageColourThemeContentModule = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();

    /***** QUERIES *****/
    const { data: get_colours_meta_data = {}, isLoading: isGetColoursMetaLoading } = katanaQuery.serviceID.meta.getColours.useQuery(Number(id));

    /***** RENDER HELPERS *****/
    const colourKeys = _.keys(get_colours_meta_data);
    const colourOptions = colourKeys.map((colourKey) => {
        const { id, hex_code } = get_colours_meta_data[colourKey];
        return { id, hex_code };
    });

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion
            title="Website Colour Scheme"
            description="Feel free to customise the default colour scheme applied from your chosen template. Select a colour that closely aligns with your brand's identity."
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    {isGetColoursMetaLoading ? <RequestLoader message="Loading Theme Colours..." /> : ''}
                    <Field
                        name={COLOUR}
                        component={ColourSelect.ReduxForm}
                        preventDeselect
                        options={colourOptions}
                        validate={[requiredFieldValidation]}
                    />
                    <br />
                    <Text italic size--xss secondary>{`Don't worry, you'll be able to change your colour later if you change your mind`}</Text>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const WebpageColourThemeContentModule = Object.assign(_WebpageColourThemeContentModule, {
    formFields: webpageColourThemeFormFieldsEnum
});

export default WebpageColourThemeContentModule;
