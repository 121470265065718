/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'svelte/store';
import { useBoolean } from 'usehooks-ts';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import SolidButton from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAllowPreviewState } from 'containers/katana/containers/ContentEditorLightbox/hooks/useAllowPreviewState';
import { katanaNextPageRoutingURL } from 'containers/katana/hooks/useKatanaNextPage';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useBooleanStore } from 'utilities/hooks/useBooleanStore';
import { useStore } from 'utilities/hooks/useStore';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaPages, katanaServicePages } from 'containers/katana/consts';
import {
    contentEditorPictureInPictureState,
    katanaContentEditorLightboxSkipWritable
} from 'containers/katana/containers/ContentEditorLightbox/consts';
import './_contentEditorActionButtons.scss';
const { ADD_SECTION } = katanaPages;
const { SETUP } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * This component is used in the Content Editor Lightbox during setup.
 */
const ContentEditorActionButtons = () => {
    /***** HOOKS *****/
    const { page, childpage, subpage } = useSetupEditorRouteParams();
    const { value: isShowingSaveWarning, setTrue: showSaveWarning, setFalse: hideSaveWarning } = useBoolean(false);
    const { value: canSkipSection } = useBooleanStore(katanaContentEditorLightboxSkipWritable);
    const [pictureState, setPictureState] = useStore(contentEditorPictureInPictureState.store);
    const isMobile = useAppViewport(['xs', 'sm']);
    const isPreviewAllowed = useAllowPreviewState();
    const history = useHistory();

    /***** FUNCTIONS *****/
    function skipSection() {
        history.push(get(katanaNextPageRoutingURL));
    }

    function handleSkipButton() {
        hideSaveWarning();
        skipSection();
    }

    function skipForNow() {
        if (!canSkipSection) {
            showSaveWarning();
            return;
        }

        skipSection();
    }

    /**
     * @param {React.SyntheticEvent<Element, Event>} e
     */
    function handleShowPreview(e) {
        e.stopPropagation();
        switch (pictureState) {
            case 'hidden':
            case 'minimised':
                setPictureState(isMobile ? 'maximised' : 'visible');
                break;

            case 'visible':
                setPictureState('maximised');
                break;

            default:
                break;
        }
    }

    /***** RENDER HELPERS *****/
    const isSetupPage = page === SETUP.to;
    const isAddSectionPage = page === ADD_SECTION;
    const showSkipButton =
        (subpage === 'navigation-and-socials' || childpage || subpage === 'contact-information') && (isSetupPage || isAddSectionPage);

    /***** RENDER *****/
    return (
        <div className="ContentEditorActionButtons">
            {isPreviewAllowed && (
                <SolidButton color="secondary" onClick={handleShowPreview}>
                    <PhosphorIcons.Eye size={20} />
                    {isMobile ? '' : <>&nbsp; Preview</>}
                </SolidButton>
            )}

            {showSkipButton && (
                <OutlineButton color="secondary" onClick={skipForNow}>
                    <PhosphorIcons.FastForward size={20} />
                    {isMobile ? '' : <>&nbsp; Skip For Now</>}
                </OutlineButton>
            )}

            <Modal.Compact isOpen={isShowingSaveWarning} onClose={hideSaveWarning} ariaLabel="Unsaved Changes Modal">
                <Text.Heading h2 align--center>
                    You have unsaved changes
                </Text.Heading>
                <Padding y={4}>
                    <Text size--m secondary align--center>
                        Are you sure you want to skip this section?
                        <br />
                        You can always come back and edit it later.
                    </Text>
                </Padding>
                <Flex justify="center">
                    <OutlineButton onClick={hideSaveWarning}>Cancel</OutlineButton>
                    <SolidButton type="onClick" onClick={handleSkipButton}>
                        Skip
                    </SolidButton>
                </Flex>
            </Modal.Compact>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { ContentEditorActionButtons };
