/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OutlineButton from 'components/Buttons/OutlineButton';
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import Padding from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { InfoTableSubHeading } from 'containers/katana/components';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServicePages, katanaSubPageEnums } from 'containers/katana/consts';
import { katanaContentEditorRoutes } from 'containers/katana/containers/ContentEditorLightbox/consts';
import './_KatanaInformationAndAppearanceModule.scss';

const { BASIC_DETAILS, CONTACT_INFORMATION } = katanaSubPageEnums;
const { EDITOR } = katanaServicePages;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _WebsiteInformationContent = () => {
    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { getKatanaDestination } = useKatanaURL();

    /***** QUERIES *****/
    const { data: get_katana_service_data } = katanaQuery.serviceID.getService.useQuery(id);

    /***** RENDER *****/
    return (
        <NXBox.DefaultPadding inject>
            <Flex direction="column" fullWidth justify="between">
                <Flex fullWidth wrap>
                    <Flex.Child grow inject>
                        <Flex direction="column" gap={0} className="KatanaInformationAndAppearanceModule__info">
                            <InfoTableSubHeading>Website name</InfoTableSubHeading>

                            <Text medium size--m black>
                                {get_katana_service_data?.business?.name ?? '-'}
                            </Text>
                        </Flex>
                    </Flex.Child>
                    <Padding x={2} inject>
                        <OutlineButton
                            size="small"
                            color="secondary"
                            width="auto"
                            to={getKatanaDestination(EDITOR.BASIC_DETAILS)}
                            onMouseOver={() => {
                                katanaContentEditorRoutes[BASIC_DETAILS].prefetch(id);
                            }}
                        >
                            Edit info
                        </OutlineButton>
                    </Padding>
                </Flex>

                <Flex direction="column" gap={0}>
                    <InfoTableSubHeading>Industry</InfoTableSubHeading>
                    <Text medium size--m black>
                        {get_katana_service_data?.attributes?.business_categories?.join?.(', ') || 'N/A'}
                    </Text>
                </Flex>
                <Flex fullWidth wrap>
                    <Flex.Child grow inject>
                        <Flex direction="column" gap={0} className="KatanaInformationAndAppearanceModule__info">
                            <InfoTableSubHeading>Contact Phone Number</InfoTableSubHeading>
                            <Text medium size--m black>
                                {get_katana_service_data?.business?.phone ?? 'N/A'}
                            </Text>
                        </Flex>
                    </Flex.Child>
                    <Padding x={2} inject>
                        <OutlineButton
                            size="small"
                            color="secondary"
                            width="auto"
                            to={getKatanaDestination(EDITOR.CONTACT_INFORMATION)}
                            onMouseOver={() => {
                                katanaContentEditorRoutes[CONTACT_INFORMATION].prefetch(id);
                            }}
                        >
                            Edit contact info
                        </OutlineButton>
                    </Padding>
                </Flex>

                <Flex direction="column" gap={0}>
                    <InfoTableSubHeading>Contact Email Address</InfoTableSubHeading>
                    <Text medium size--m black>
                        {get_katana_service_data?.business?.email ?? 'N/A'}
                    </Text>
                </Flex>
            </Flex>
        </NXBox.DefaultPadding>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
