import { VIPsitesIcon } from 'components/Icons/VIPsites';

export const company = 'VentraIP Australia';

export const application = 'VIPcontrol';

export const url = 'https://ventraip.com.au/';

export const abn = '91 137 543 018';

export const api = 'https://vip.ventraip.com.au/';

export const dev_api = 'https://preview-vip.ventraip.net.au/';

export const promotions = {
    auDirect: { start: 'Sep 21, 2022 09:00:00', end: null },
    freeDotOnline2024: {
        start: 'Jan 01, 2024 00:00:00',
        end: 'Dec 31, 2024 23:59:00'
    },
    freeDotStore2024: {
        start: 'Jan 01, 2024 00:00:00',
        end: 'Dec 31, 2024 23:59:00'
    },
    freeDotSite2024: {
        start: 'Jan 01, 2024 00:00:00',
        end: 'Dec 31, 2024 23:59:00'
    },
    vipcontrolApp: {
        start: 'Aug 24, 2023 09:00:00',
        end: null
    },
    additionalUser: {
        start: 'Feb 01, 2024 00:00:00',
        end: null
    },
    freeDotXyz2024: {
        start: 'Jan 01, 2024 00:00:00',
        end: 'Dec 31, 2024 23:59:00'
    },
    megamay2024: {
        start: 'May 01, 2024 08:00:00',
        end: 'May 31, 2024 23:59:00'
    },
    nrlGiveaway2024: {
        start: 'Jul 17, 2024 09:00:00',
        end: 'Sep 06, 2024 23:59:00'
    },
    aflGrandFinalGiveaway2024: {
        start: 'Oct 03, 2024 09:00:00',
        end: 'Oct 04, 2024 23:59:00'
    },
    nrlGiveaway2024_2: {
        start: 'Sep 05, 2024 09:00:00',
        end: 'Oct 11, 2024 23:59:00'
    }
};

export const ticket_name = 'eTicket';

export const service_status = 'https://status.ventraip.com.au/';

export const support_number = '13 24 85';

export const recovery_email = 'recovery@ventraip.com.au';

export const terms_url = 'https://ventraip.com.au/terms-policies-agreements/';

export const whois_lookup_url = 'https://ventraip.com.au/whois/?whois=';

export const eft_details = { name: 'VentraIP', bsb: '083 376', account: '294 737 269' };

export const bpay_details = { biller_code: '556407' };

export const activeProducts = {
    list: [
        'beginner',
        'advanced',
        'professional',
        'starter',
        'freedom',
        'premier',
        'starter plus',
        'freedom plus',
        'premier plus',
        'select-hosting',
        'starter+',
        'freedom+',
        'premier+'
    ],
    max: ['professional', 'premier', 'premier plus']
};

export const restrictedProducts = [
    'BEGINNER',
    'ADVANCED',
    'PROFESSIONAL',
    'SYD-WHMMULTI',
    'SYD-WHMMULTI-PLUS',
    'MEL-WHMMULTI',
    'MEL-WHMMULTI-PLUS',
    'Netorigin Starter Reseller Hosting',
    'Netorigin Standard Reseller Hosting',
    'Netorigin Elite Reseller Hosting',
    'Zuver Multi Hosting',
    'Zuver Multi Hosting with Power Pack'
];

export const billingCycles = {
    'Monthly': '/mo',
    'Quarterly': '/qr',
    'Semi-Annually': '/sa',
    'Annually': '/yr',
    'Biennially': '/2 yrs',
    'Triennially': '/3 yrs',
    '1 Year': '/yr'
};

export const ventraSynMigrationBanners = true;

export const ventraSynMigrationShowEndOfLifeDate = false;

export const ventraSynMigrationEndOfLifeDate = 'August 31, 2022';

export const ventraSynMigrationHalfPriceStartDate = '10 February 2022 00:00:00';

export const ventraSynMigrationHalfPriceEndDate = '31 August 2022 23:59:00';

export const synergyLoginUrl = 'https://manage.synergywholesale.com/';

export const synergyPasswordReset = 'https://manage.synergywholesale.com/passwdreset';

export const auDirectLandingPageUrl = 'https://ventraip.com.au/domain-names/extensions/au';

export const auDirectCat1CutOff = '4th February 2018';

export const auDirectApplicationOpen = '24th March 2022';

export const auDirectApplicationClose = '21st September 2022';

export const audaPriorityTokenRetrievalToolUrl = 'https://priority.auda.org.au/';

export const audaPriorityStatusToolUrl = 'https://www.auda.org.au/tools/priority-status-tool';

export const audaPriorityApplicationProcessUrl = 'https://www.auda.org.au/au-domain-names/au-direct/priority-allocation-process';

export const nonDirectAuTLDs = ['.com.au', '.net.au', '.org.au', '.id.au', '.asn.au'];

export const dashboardPromoBannerDismissible = true;

export const hostingRenewPromoBillingCycles = {
    'Monthly': true,
    'Quarterly': true,
    'Semi-Annually': true,
    'Annually': true,
    'Biennially': false,
    'Triennially': false
};

export const serviceNav = /** @type {const} */ ({
    'Domain Names': { key: 'domains', link: '/my-services/domains', icon: 'domain-names' },
    'VIPsites': { key: 'vip-sites', link: '/my-services/vip-sites', icon: VIPsitesIcon },
    'cPanel Hosting': { key: 'hosting', link: '/my-services/hosting', icon: 'business-hosting' },
    'Email Hosting': { key: 'email', link: '/my-services/email-hosting', icon: 'email-hosting' },
    'Google Workspace': { key: 'google', link: '/my-services/google', icon: 'gsuite' },
    'Microsoft 365': { key: 'microsoft-365', link: '/my-services/microsoft-365', icon: 'microsoft' },
    'VPS': { key: 'vps', link: '/my-services/vps', icon: 'vps' },
    'SSL Certificates': { key: 'ssl', link: '/my-services/ssl', icon: 'ssl2' }
});

const cpanelReservedAliasesRoot = [
    'abuse',
    'adm',
    'amandabackup',
    'apache',
    'bin',
    'canna',
    'daemon',
    'dbus',
    'decode',
    'desktop',
    'dovecot',
    'dumper',
    'fax',
    'ftp',
    'games',
    'gdm',
    'gopher',
    'halt',
    'hostmaster',
    'ident',
    'ingres',
    'ldap',
    'lp',
    'mail',
    'mailnull',
    'manager',
    'mysql',
    'named',
    'netdump',
    'news',
    'nfsnobody',
    'nobody',
    'noc',
    'nscd',
    'ntp',
    'nut',
    'operator',
    'pcap',
    'postfix',
    'postgres',
    'postmaster',
    'privoxy',
    'pvm',
    'quagga',
    'radiusd',
    'radvd',
    'root',
    'rpc',
    'rpcuser',
    'rpm',
    'security',
    'shutdown',
    'smmsp',
    'squid',
    'sshd',
    'sync',
    'system',
    'toor',
    'uucp',
    'vcsa',
    'webalizer',
    'webmaster',
    'wnn',
    'xfs'
];
const cpanelReservedAliasesPostMaster = ['info', 'mailer-daemon', 'marketing', 'sales', 'support'];
const cpanelReservedAliasesNews = ['newsadm', 'newsadmin', 'usenet'];
const cpanelReservedAliasesFTP = ['ftp-adm', 'ftp-admin', 'ftpadm', 'ftpadmin'];
const cpanelReservedAliasesWebMaster = ['www'];
export const cpanelReservedAliases = [
    ...cpanelReservedAliasesRoot,
    ...cpanelReservedAliasesPostMaster,
    ...cpanelReservedAliasesNews,
    ...cpanelReservedAliasesFTP,
    ...cpanelReservedAliasesWebMaster
];

export const postLoginChecklistItems = {
    USER_DETAILS: {
        name: 'userDetails',
        on: true
    },
    ENABLE_TWO_FACTOR: {
        name: 'enableTwoFactor',
        on: true
    }
};
