/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import { useMemo, useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorRenderModules from '../contentEditorRenderModules';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import useModifiedInitialValues from 'containers/katana/hooks/useModifiedInitialValues';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { LOGO } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;
const { STYLE } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {FormHandlerComponent}
 */
const BasicDetailsFormHandler = ({ subpageRouteData }) => {
    /***** STATE *****/
    const [isSubmitting, setIsSubmitting] = useState(false);

    /***** HOOKS *****/
    const { id, page, subpage } = useSetupEditorRouteParams();
    const { modules } = subpageRouteData;
    const { performRouting } = useKatanaNextPage(subpageRouteData);
    const { submitSiteData } = useSiteDataPreviewValues();

    /***** QUERIES *****/
    const { data: get_katana_site_business_info_data, isLoading: isGetKatanaSiteBusinessInfoLoading } =
        katanaQuery.serviceID.getBusinessInfo.useQuery(id);

    const { data: get_katana_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(id);

    const { mutateAsync: mutateUpdateKatanaSiteBusinessInfo } = useUpdateKatanaSiteBusinessInfoMutation(id, { onSuccess: _.noop });
    const { mutateAsync: mutateUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(id, { onSuccess: _.noop });

    /***** HOOKS *****/
    const queryData = useMemo(
        () => ({
            ...(get_katana_site_business_info_data ?? {}),
            ...(get_katana_site_info_data ?? {})
        }),
        [get_katana_site_business_info_data, get_katana_site_info_data]
    );
    const newInitialValues = useModifiedInitialValues(queryData, modules, isSubmitting);

    /***** FUNCTIONS *****/
    /**
     * @param {Record<string,any>} values
     */
    function handleSubmit(values) {
        const clonedValues = _.cloneDeep(values);
        if (clonedValues?.logo_choice === LOGO) {
            _.set(clonedValues[STYLE], 'fonts.brand', null);
        }
        if (clonedValues?.logo_choice === STYLE) {
            clonedValues[LOGO] = null;
        }

        setIsSubmitting(true);

        const promises =
            subpage === 'contact-information'
                ? [mutateUpdateKatanaSiteBusinessInfo(_.merge({}, clonedValues, _.pick(get_katana_site_business_info_data, ['business_categories'])))]
                : [mutateUpdateKatanaSiteBusinessInfo(clonedValues), mutateUpdateKatanaSiteInfo(clonedValues)];

        submitSiteData(promises);

        return Promise.all(promises)
            .then((e) => {
                katanaQuery.serviceID.getService.optimisticallyAddLaunchChecklistUpdate(id, 'webpage-info-completed');
                if (page !== 'setup') {
                    pushNotification({ status: 200, message: 'Successfully updated site details.' });
                }
                performRouting();
                return e;
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    /***** RENDER *****/
    if (isGetKatanaSiteBusinessInfoLoading || isGetKatanaSiteInfoLoading) return <RequestLoader message="Loading Details..." />;
    return (
        <ContentEditorRenderModules subpageRouteData={subpageRouteData} subpage={subpage} onSubmit={handleSubmit} initialValues={newInitialValues} />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default BasicDetailsFormHandler;
