/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has, keys } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { WrappedFieldProps } from 'redux-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import NXCard from 'components/NXCard';
import Border from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ContentOptionCard } from 'containers/katana/components/contentOptionCard';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import ContentOptionsCardGrid from 'containers/katana/containers/contentEditorModules/chooseWebLayout/contentOptionsCardGrid';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/queryTree';
import { useCreateKatanaThemeMutation } from 'containers/katana/queries/useCreateKatanaThemeMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useMapSectionDefinitionToOptionsCardData } from 'containers/katana/containers/contentEditorModules/chooseWebLayout/methods';
import { useSetupEditorRouteParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useStateEffect } from 'utilities/hooks/useStateEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

const { PRESET_ID } = useCreateKatanaThemeMutation.FORM_FIELD_KEYS;

function contentOptionsSetIncluded(
    prevIncludedSections: KatanaNamespace.SectionDefinitions.IDs[],
    sectionID: KatanaNamespace.SectionDefinitions.IDs
) {
    if (prevIncludedSections.includes(sectionID)) {
        return prevIncludedSections.filter((prevSectionID) => prevSectionID !== sectionID);
    } else {
        return [...prevIncludedSections, sectionID];
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SectionSelectionGridReduxForm: React.FC<WrappedFieldProps> = ({
    /**
     * Redux Props
     */
    input,
    meta
}) => {
    const { value, onChange } = input;

    /***** HOOKS *****/
    const { id } = useSetupEditorRouteParams();
    const { form } = useContext(ContentEditorRenderModulesContext);
    const formValues = useSelector((state) => state?.form?.[form]?.values);

    /***** QUERIES *****/
    const { data: get_katana_theme_presets_data, isLoading: isGetKatanaThemePresetsLoading } =
        katanaQuery.serviceID.meta.getThemePresets.useQuery(id);
    const { data: get_katana_section_definitions_data, isLoading: isGetKatanaSectionDefinitionsOrderedLoading } =
        katanaQuery.serviceID.meta.getSectionDefinitions.useQuery(id);

    const selectedPresetSections = get_katana_theme_presets_data?.[formValues[PRESET_ID]]?.sections as KatanaNamespace.SectionDefinitions.IDs[];

    /***** STATE *****/
    const [includedSections, setIncludedSections] = useStateEffect(selectedPresetSections);

    useEffect(() => {
        if (get_katana_theme_presets_data && includedSections.length === 0) {
            setIncludedSections(selectedPresetSections);
        }
    }, [get_katana_theme_presets_data]);

    const spareSectionDefinitions = keys(get_katana_section_definitions_data).filter(
        (sectionID) => !selectedPresetSections.includes(sectionID)
    ) as KatanaNamespace.SectionDefinitions.IDs[];

    useEffect(() => {
        if (value?.length !== includedSections?.length) {
            onChange(includedSections);
        }
    }, [includedSections]);

    /**
     * First we need to filter the section definitions incase the sectionID doesn't exist in the section definitions
     * (this can happen if the section is deleted from the section definitions but still exists in the theme preset)
     */
    const filteredPresetSections = selectedPresetSections.filter((sectionID) => has(get_katana_section_definitions_data, sectionID));

    const hasSpareSections = Boolean(spareSectionDefinitions?.length);

    /**
     * Create the preset content options data list
     */
    const presetContentOptionsDataList = useMapSectionDefinitionToOptionsCardData(id, filteredPresetSections);

    /**
     * Create the spare content options data list that the user can select
     */
    const spareContentOptionsDataList = useMapSectionDefinitionToOptionsCardData(id, spareSectionDefinitions);

    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isGetKatanaSectionDefinitionsOrderedLoading, message: 'Loading section definitions...' },
        { condition: isGetKatanaThemePresetsLoading, message: 'Loading theme templates...' }
    ]);

    function onSwitchClick(sectionID: KatanaNamespace.SectionDefinitions.IDs) {
        const updatedSectionList = contentOptionsSetIncluded(includedSections, sectionID);
        setIncludedSections(updatedSectionList);
    }

    const hasPresetSections = Boolean(presetContentOptionsDataList?.length);

    /***** RENDER *****/
    return (
        <ContentEditorModuleContent>
            <RequestLoader.MultiLoader loaders={multiLoaderData}>
                {hasPresetSections && (
                    <>
                        <NXBox.Title title="Here are the default content options included in the template you just selected" />
                        <ContentOptionsCardGrid contentOptionsDataList={presetContentOptionsDataList}>
                            {(props) => (
                                <ContentOptionCard {...props}>
                                    <Border top inject>
                                        <NXCard.Actions>
                                            <CheckBoxList.Item
                                                className="ContentOptionCard__checkBoxItem"
                                                isChecked={includedSections.includes(props.sectionDefinitionID)}
                                                onChange={() => onSwitchClick(props.sectionDefinitionID)}
                                            >
                                                <Text uppercase black semiBold size--xs>
                                                    Include in template
                                                </Text>
                                            </CheckBoxList.Item>
                                        </NXCard.Actions>
                                    </Border>
                                </ContentOptionCard>
                            )}
                        </ContentOptionsCardGrid>
                    </>
                )}
                {hasSpareSections && (
                    <>
                        <NXBox.Title title="Do you want to add any other sections to your website?" />
                        <ContentOptionsCardGrid contentOptionsDataList={spareContentOptionsDataList}>
                            {(props) => (
                                <ContentOptionCard {...props}>
                                    <Border top inject>
                                        <NXCard.Actions>
                                            <CheckBoxList.Item
                                                className="ContentOptionCard__checkBoxItem"
                                                isChecked={includedSections.includes(props.sectionDefinitionID)}
                                                onChange={() => onSwitchClick(props.sectionDefinitionID)}
                                            >
                                                <Text uppercase black semiBold size--xs>
                                                    Include in template
                                                </Text>
                                            </CheckBoxList.Item>
                                        </NXCard.Actions>
                                    </Border>
                                </ContentOptionCard>
                            )}
                        </ContentOptionsCardGrid>
                    </>
                )}
                <ValidationMessage.ReduxForm.Default {...meta} />
            </RequestLoader.MultiLoader>
        </ContentEditorModuleContent>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
